import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'

import { css } from '@emotion/core'
import 'twin.macro'

import SocialIcons from './social-icons'

import LogoImg from '../svgs/logo.svg'

import {
  outTransition,
  inTransition,
  outDuration,
  inDuration,
} from '../transitions/fade'

const SiteHead = () => (
  <header tw="pt-6 pb-16 lg:pb-12" data-gsap="site-head">
    <div tw="container">
      <div tw="flex justify-center md:justify-between items-center">
        <TransitionLink
          exit={{ trigger: outTransition, length: outDuration }}
          entry={{
            trigger: inTransition,
            length: inDuration,
            delay: outDuration,
          }}
          to="/"
        >
          <LogoImg
            css={css`
               {
                width: 318px;
                height: 23px;
              }
            `}
          />
        </TransitionLink>
        <div tw="hidden md:block">
          <SocialIcons />
        </div>
      </div>
    </div>
  </header>
)

export default SiteHead
