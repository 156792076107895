import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import 'twin.macro'

import SiteHead from '../components/site-head'
import ButtonLink from '../components/button-link'
import SiteFoot from '../components/site-foot'

const NotFoundPage = ({ data }) => {
  const { errorPage } = data

  return (
    <>
      <Helmet>
        <title>Sheila Bird Studio | 404</title>
      </Helmet>
      <SiteHead />
      <div tw="container">
        <div tw="max-w-2xl mx-auto text-2xl md:text-3xl text-center mt-12 mb-24">
          <h1 tw="font-normal text-4xl mb-8">{errorPage.heading404}</h1>
          <div
            tw="mb-8"
            dangerouslySetInnerHTML={{
              __html: errorPage.body404,
            }}
          ></div>
          <ButtonLink
            to="/"
            text="View projects"
            hoverText="View projects"
            bgColour="white"
          />
        </div>
      </div>
      <SiteFoot />
    </>
  )
}

export default NotFoundPage

export const query = graphql`
  query {
    errorPage: craftErrorPageGlobalSet {
      heading404
      body404
    }
  }
`
